.card-style {
    margin: 10px 0;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(227, 232, 236, 1);
}

.card-style .ant-card-head {
    color: rgba(0, 52, 91, 1);
    font-size: 16px;
    border-bottom: none;
}

.card-style .ant-card-body {
    padding-top: 5px;
}

.float-right {
    float: right;
}

.sidebar-title {
    font-size: 18px;
    font-weight: 500;
    margin-left: 15px;
}

.card-layout-content {
    margin: 10px 0;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(227, 232, 236, 1);
}

.text-layout-content {
    font-size: 16px;
}

.title-layout-content {
    color: rgba(0, 52, 91, 1) !important;
    padding: 10px 0;
}

.mas-button {
    min-width: 100px;
    font-size: 12px;
    height: 30px;
    text-transform: none !important;
    padding: 0 !important;
    background-color: rgb(205 83 52);
    color: white;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2), 0 5px 15px 0 rgba(0, 0, 0, 0.19);
    border-radius: 1rem;
    border: none;
    margin-right: 5px;
}

.content-padding {
    padding-top: 20px;
}

@media (max-width: 600px) {
    .content-padding {
        padding: 0 5px;
    }
}


.source-icon {
    color: rgba(205, 83, 52, 1);
    font-size: 2em !important;
}

.small-card .ant-comment-actions {
    text-align: center;
}

@media (max-width: 600px) {
    .small-card .ant-descriptions-title {
        overflow: auto;
        max-width: 300px;
    }

    .ant-typography.title-layout-content.result-title {
        text-align: center;
        width: 100%;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}


.card-style .fts-search-input {
    border: none;
    background-color: white;
    /*padding: 10px;*/
}

.card-fts-search .ant-card-body {
    margin: auto;
    padding: 0;
    width: 100%;
}

.card-fts-search .fts-search-input-wrapper {
    padding: 5px;
    border-style: solid;
    border-radius: 10px;
    border-color: #002E4D;
    border-width: 2px;
}

.info-ddjj {
    padding: 10px;
    border-style: solid;
    border-radius: 10px;
    border-color: #002E4D;
    border-width: 2px;
    background-color: #002E4D;
    color: #ffffff !important;
}

.multi-search .list-search {
    background-color: black;
}

.name-result-link {
    color: rgba(0, 52, 91, 1) !important;
    font-weight: 500;
}