
.VideoTutoriales-page {
    padding: 40px;
    background-image: url('../assets/imgs/background3.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
}

.VideoTutoriales-page .cards {
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-left: 4vw;
    padding-right: 4vw;
}

.VideoTutoriales-page .cards .ant-card {
    margin: auto;
    box-shadow: 0px 5px 15px rgba(42, 42, 42, 0.06);
    border-radius: 20px;
}


.ant-card-meta {
    padding-bottom: 5%;
}

.ant-card-meta-title {
    margin-bottom: 25px;
    color: rgba(0, 52, 91, 1) !important;
    font-weight: bolder;
}

.title-paragraph {
    font-weight: bolder;
    font-size:30px;
}

.iframe-container{
position: relative;
width: 100%;
padding-bottom: 56.25%; 
height: 0;
}

.iframe-container iframe{
position: absolute;
top:0;
left: 0;
width: 100%;
height: 100%;
}